import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker and INSTAR Alarmserver",
  "path": "/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/",
  "dateChanged": "2020-03-24",
  "author": "Mike Polinowski",
  "excerpt": "How can I use the INSTAR alarm server with ioBroker?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - ioBroker and INSTAR Alarmserver' dateChanged='2020-03-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use the INSTAR alarm server with ioBroker?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/' locationFR='/fr/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "iobroker-and-instar-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-and-instar-alarmserver",
        "aria-label": "iobroker and instar alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker and INSTAR Alarmserver`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How can I use the alarm server of my camera with the ioBroker Client as target web server that is configured on port 8087 using the adapter Simple-API?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The webhook required for the alarm server can be set up via the `}<strong parentName="p">{`Simple RESTful API`}</strong>{` adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "743px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/f2793/ioBroker_and_INSTAR_Alarmserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACjklEQVQozx2NS2jTYACAgxcPnrx4VBDBiwpeRPDgC0EEb14mYxSmKCIKQ4cPZG4w0IFzwzlQ3CZWLLLNOdc9dHbd1m3arV2fSZr8eTX5/6TpmjZJ09faJeI+vsN3+7ADR081Pxsc/x1se/Xl6uORG9Pl9oVq63SpzVdrn9M++EjvJpwMiVOb4rdY9vb8dsts3TVXd83WXN4yhu0/3Dk0ubAWeTexfLl9+MWy0eXXO/1616L5YGbLHWCXktCXRL4w98sXHZhiXv/g+r6D3jmpd1XH9uzdd+RC0/WHPZfu9rV2u/tX8k2jmmtCu+fVhte1BDRMQ1cNK8+l0dCI4f5YH/dsu0es+Z/Foo4dOnnu4Ikzp1seHb9y89qdjk+bhVuzxnO//n7DRJrFKCYt5LbyBUVGsqrqRauwa94saoUCthqK/10PR0k2nmLktNAfqRwbrXWE7AC0v/LOdFCNPe3nssbF82ebm5sd27Ysq1Qq16slyzQwJi1ncobjOLbjOE7DSxXvL5U+E7VAuhaQtqeS5puxFAvVwbcDHo/Hskq6YVqmPhbXg2wO6140Xq5YPX+qT/zlnrVyRkYCQ3M0lUqRDE2F48RMhJdlJauqoigyDAAA8Bw7HwYRSsDWKDXEaalMmc7V03oDIkTTgKYBwzCAYVkAFJFXVZUgiEgkguPJWDSaTOIKTGdkhCnZXF7TKiXLcWzbtmVZliQJQgQhlOD/FEVJURRBEARe4Hme4ziWZdOiCCHCspqeK5j1xs6O7TR2bIRQOBRaD26EwmGCJBkGkCSpqirP8wDQ7C6SKLEsCyHEKtVq3jCtcsXZXSOE8GQykUjgOA5owLAMRVGZTAYAEIvFcBwnCILjOIqiJEn6B8koDa6yd2ZJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52ff3f0f91fbbd1671efb1df07e235c3/e4706/ioBroker_and_INSTAR_Alarmserver_01.avif 230w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/d1af7/ioBroker_and_INSTAR_Alarmserver_01.avif 460w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/96163/ioBroker_and_INSTAR_Alarmserver_01.avif 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52ff3f0f91fbbd1671efb1df07e235c3/a0b58/ioBroker_and_INSTAR_Alarmserver_01.webp 230w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/bc10c/ioBroker_and_INSTAR_Alarmserver_01.webp 460w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/53666/ioBroker_and_INSTAR_Alarmserver_01.webp 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52ff3f0f91fbbd1671efb1df07e235c3/81c8e/ioBroker_and_INSTAR_Alarmserver_01.png 230w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/08a84/ioBroker_and_INSTAR_Alarmserver_01.png 460w", "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/f2793/ioBroker_and_INSTAR_Alarmserver_01.png 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52ff3f0f91fbbd1671efb1df07e235c3/f2793/ioBroker_and_INSTAR_Alarmserver_01.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The adapter is configured to listen on all network interfaces (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0.0.0.0`}</code>{`) on the default port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8087`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "840px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4dbde97e83d65075d7f57741868657c6/1e088/ioBroker_and_INSTAR_Alarmserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABu0lEQVQoz42R0W7UMBBF83eohUdeAAnxxA/wUXQlBFUFT0hI/AAVi1i6pSLxJrEdx54Z20mTLWyKbO+WrooQR1eRPfGd3IyzF+/94+P+2Un/9KR/9KZ/chwWD193B0f+/uyPDmf+4CjocOYfzPy9l/75W5dBN3Y/r7urqe0nRr8qt6ncBoYJo+w4uXGCywkuN916011NMEw0TG59rd2Y5fmPT6enZC2ShZ3Ibum97bwdO1so++6be/XFXki77kPRO8rqqjo/XyICbYWEewCis1hI+LjUH85MIdFRKCJi1jTKAIbPIgUB3lWrjUUYPA4+OG/IuGxKLkWjatHwRrUG70oZgBgB9kNlUooVY7yuq7JcrZiUkgh3J7cCACGElBLTJhLMKtLEp5SyUcr8jTZyuwIAWR1hjK0iQgj8bzLOeRVhjGmtKYUGoB3/MiOiMaYsS6VUsimlUoo8z4uiUEoRkSUMffbbZWnDOQ8/3DSphRBiPp8vFouqqgDAEkpNSgMYo7U2xtxMW7ZtS0QQXpk0RgDQWqfwAEim/Vzarxfs+/KMsXQjtDXXdS0j6Q5Si7RIaG0sYdsqIcTtQfwG3Y33JRXrbVoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dbde97e83d65075d7f57741868657c6/e4706/ioBroker_and_INSTAR_Alarmserver_02.avif 230w", "/en/static/4dbde97e83d65075d7f57741868657c6/d1af7/ioBroker_and_INSTAR_Alarmserver_02.avif 460w", "/en/static/4dbde97e83d65075d7f57741868657c6/32fee/ioBroker_and_INSTAR_Alarmserver_02.avif 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4dbde97e83d65075d7f57741868657c6/a0b58/ioBroker_and_INSTAR_Alarmserver_02.webp 230w", "/en/static/4dbde97e83d65075d7f57741868657c6/bc10c/ioBroker_and_INSTAR_Alarmserver_02.webp 460w", "/en/static/4dbde97e83d65075d7f57741868657c6/ed60d/ioBroker_and_INSTAR_Alarmserver_02.webp 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dbde97e83d65075d7f57741868657c6/81c8e/ioBroker_and_INSTAR_Alarmserver_02.png 230w", "/en/static/4dbde97e83d65075d7f57741868657c6/08a84/ioBroker_and_INSTAR_Alarmserver_02.png 460w", "/en/static/4dbde97e83d65075d7f57741868657c6/1e088/ioBroker_and_INSTAR_Alarmserver_02.png 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4dbde97e83d65075d7f57741868657c6/1e088/ioBroker_and_INSTAR_Alarmserver_02.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can test if the service is active by calling the following URL in your browser (exchange the IP `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{` with the address of your ioBroker server)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8087/help`}</code></pre></div>
    <p>{`You should receive the following answer:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"getPlainValue"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/getPlainValue/stateID"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"get"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/get/stateID/?prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"getBulk"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/getBulk/stateID1,stateID2/?prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/set/stateID?value=1&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"toggle"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/toggle/stateID&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"setBulk"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/setBulk?stateID1=0.7&stateID2=0&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"setValueFromBody"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/setValueFromBody?stateID1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"objects"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/objects?pattern=system.adapter.admin.0*&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"states"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/states?pattern=system.adapter.admin.0*&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"search"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/search?pattern=system.adapter.admin.0*&prettyPrint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"query"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://192.168.2.111:8087/query/stateID1,stateID2/?dateFrom=2019-06-06T12:00:00.000Z&dateTo=2019-06-06T12:00:00.000Z&noHistory=false&prettyPrint"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We now have URLs where we can read ioBroker data points (system states) `}<strong parentName="p">{`GET`}</strong>{` and change them `}<strong parentName="p">{`SET`}</strong>{`.`}</p>
    <h2 {...{
      "id": "creating-the-data-point",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-the-data-point",
        "aria-label": "creating the data point permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the data point`}</h2>
    <p>{`As data point we can use all entries in the ioBroker object tree. We have already shown in an earlier FAQ how to use such an entry to control the camera via CGI commands:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/ioBroker_without_MQTT/"
        }}>{`ioBroker integration without MQTT`}</a></li>
    </ul>
    <p>{`If you already have such an item in your list of objects, you can use it to switch a state in `}<strong parentName="p">{`camera 1`}</strong>{` via the alarm server of `}<strong parentName="p">{`camera 2`}</strong>{` and vice versa. You can find examples for this in our `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/IOBroker/"
      }}>{`ioBroker overview`}</a>{`.`}</p>
    <p>{`In the following we would like to use the `}<a parentName="p" {...{
        "href": "/en/Erweitert/INSTAR_MQTT_Broker/"
      }}>{`MQTT interface of an INSTAR Full HD camera`}</a>{`, to implement the following scenario.`}</p>
    <ol>
      <li parentName="ol">{`Camera 1 detects motion and triggers alarm and starts alarm recording.`}</li>
      <li parentName="ol">{`Then the alarm server of camera 1 contacts our ioBroker REST API and switches a variable inside the object tree.`}</li>
      <li parentName="ol">{`This in turn should allow camera 2 to move from home position 1 to alarm position 2 so that we can see what triggered the alarm on camera 1. If there is still a person in the detection area of position 2, the motion detection will be triggered and also record a video.`}</li>
      <li parentName="ol">{`After an adjustable time has elapsed, camera 2 returns to the home position.`}</li>
    </ol>
    <p>{`Please refer to our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/#mqtt-adapter"
      }}>{`ioBroker MQTT Guide`}</a>{` for information on how to use a tool like `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/#mqttfx"
      }}>{`MQTT.fx`}</a>{` to add the necessary MQTT data points to the ioBroker object tree, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/10D1DC21F5DB/features/ptz/preset/raw`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e006d8288fa916642a346c3ce9a6ad1/72aae/ioBroker_and_INSTAR_Alarmserver_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA50lEQVQY02XLz06DMBwAYJ5HnR62/mhpIWUTmEjpmNFteDAm/AnqnHjYsmfw9WjL4xiJhxm/+2cprYwxfd+bU1oZ1Wn1Q2tt/tFad11nxfPoNo6D4Hp2YjoXoVzdJOndchmEIefc/4tzHkah5WYFow6llDLGGPU8lxCCmQcOo5T6vu84DgDYNobJGKEJQgAACCFCsLV4bqVMkyQRaSqEyBaZlCn3pyS6HxN+cX52NbgcjcCd2dQbImCMEULW5/vrbvDxqz0c9vXLtj5+PdW7zfohzx/zPN+sV8V2X7+1dVWWRdE0TVlV3zPzd7Z/zguDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e006d8288fa916642a346c3ce9a6ad1/e4706/ioBroker_and_INSTAR_Alarmserver_03.avif 230w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/d1af7/ioBroker_and_INSTAR_Alarmserver_03.avif 460w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/7f308/ioBroker_and_INSTAR_Alarmserver_03.avif 920w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/3332c/ioBroker_and_INSTAR_Alarmserver_03.avif 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e006d8288fa916642a346c3ce9a6ad1/a0b58/ioBroker_and_INSTAR_Alarmserver_03.webp 230w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/bc10c/ioBroker_and_INSTAR_Alarmserver_03.webp 460w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/966d8/ioBroker_and_INSTAR_Alarmserver_03.webp 920w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/6bfb6/ioBroker_and_INSTAR_Alarmserver_03.webp 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e006d8288fa916642a346c3ce9a6ad1/81c8e/ioBroker_and_INSTAR_Alarmserver_03.png 230w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/08a84/ioBroker_and_INSTAR_Alarmserver_03.png 460w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/c0255/ioBroker_and_INSTAR_Alarmserver_03.png 920w", "/en/static/3e006d8288fa916642a346c3ce9a6ad1/72aae/ioBroker_and_INSTAR_Alarmserver_03.png 964w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e006d8288fa916642a346c3ce9a6ad1/c0255/ioBroker_and_INSTAR_Alarmserver_03.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "814px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b466a4caa23c43b9df4cc26d71049f8a/a4262/ioBroker_and_INSTAR_Alarmserver_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAfUlEQVQI113GWwrCMBRF0cx/UooigiKW0japyYdRS5L7rNYBCApVPCwO20CJkAMgp5SJRX8mIjL/h7ypEiEgGiYogyMEYRYi/sK5VfgPMQuisXlqL+dt49s0HmNZVnZV94vKrmu/99dNEw7hdsKny3dXHl3SLo89TAPQLtIL6PSilx45PYEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b466a4caa23c43b9df4cc26d71049f8a/e4706/ioBroker_and_INSTAR_Alarmserver_04.avif 230w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/d1af7/ioBroker_and_INSTAR_Alarmserver_04.avif 460w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/8a320/ioBroker_and_INSTAR_Alarmserver_04.avif 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b466a4caa23c43b9df4cc26d71049f8a/a0b58/ioBroker_and_INSTAR_Alarmserver_04.webp 230w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/bc10c/ioBroker_and_INSTAR_Alarmserver_04.webp 460w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/f23e7/ioBroker_and_INSTAR_Alarmserver_04.webp 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b466a4caa23c43b9df4cc26d71049f8a/81c8e/ioBroker_and_INSTAR_Alarmserver_04.png 230w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/08a84/ioBroker_and_INSTAR_Alarmserver_04.png 460w", "/en/static/b466a4caa23c43b9df4cc26d71049f8a/a4262/ioBroker_and_INSTAR_Alarmserver_04.png 814w"],
              "sizes": "(max-width: 814px) 100vw, 814px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b466a4caa23c43b9df4cc26d71049f8a/a4262/ioBroker_and_INSTAR_Alarmserver_04.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To query the state of such data points via the REST API you can use the following URL`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8087/get/mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw`}</code></pre></div>
    <p>{`The answer you receive will look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ack"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1580373308245`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"q"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"system.adapter.mqtt.0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"system.user.admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"lc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1580373348378`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"common"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/10D1DC21F5DB/features/ptz/preset/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"role"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"variable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"desc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt client variable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"number"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"native"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/10D1DC21F5DB/features/ptz/preset/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"state"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`You can see that the `}<em parentName="p">{`value`}</em>{` is currently set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "0"}`}</code>{`. This means that the camera is currently in the home position `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`. To pan it to position `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` we can call up the following URL in our browser:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8087/set/mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw?value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{`Setting the value of the variable to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` causes the camera to move to position `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` and you will receive the following confirmation in the browser:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "alarm-server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-server-configuration",
        "aria-label": "alarm server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm server configuration`}</h2>
    <p>{`Now we are able to change the orientation of `}<strong parentName="p">{`camera 2`}</strong>{` via an HTTP request. In order to let `}<strong parentName="p">{`camera 1`}</strong>{` take over this task, we need to add the required URL to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server Configuration`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c105960cca79fdb38be7d48bb4954160/2e694/ioBroker_and_INSTAR_Alarmserver_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABn0lEQVQoz02Qy26bQBSG/R6pJbtyQDXMxhZ4sMVNGXAZcxmGYZgYSMFN1ajKrpu6WVV9B2fVDVWlLvqQFdiN+unX0dl8Or/OCACgquCNPDO2gnz55T+e3E/P9sPp/1gfT9vH5/DzD+ixLXKvrl6Nx+PpdDpSe4B8/drE4u77H/HU8a8dO3b58eeQfs+O3e1T9+7bb8NL11CXJPl6oJeBqsqyDLVlzkXKiiwvKONpllPGKTtPnuaC5mKlLd4GgeM4pmnO5/NBBqCXDaNtD217aNo2zzlJaRQnURwHeDckDPMmihPOOYSGruuyLPeyoiiSJEEIq6rinBNCKKVxHN/cIMuyNgPr9dq1LcYYpXS5XK5Wq9lsNlIURVXVs1zXtRAiyzLGGCEEYxz8A2O8C0NCSBRFuq5vNpvJZHKp/XJ5v99XVVXXNec8TVM6kBLChbg/fMBBEIYhQmixWPSyMvAil2VZFAUbOFfoqzL2nvO7h1tv6yOEfN/XNO0iXx4GYdu2TdOUZUkpTZLE8zzHcVzHsV13Z9sBRcj3TNO0BwAAfwHtHZlu6QPi3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c105960cca79fdb38be7d48bb4954160/e4706/ioBroker_and_INSTAR_Alarmserver_05.avif 230w", "/en/static/c105960cca79fdb38be7d48bb4954160/d1af7/ioBroker_and_INSTAR_Alarmserver_05.avif 460w", "/en/static/c105960cca79fdb38be7d48bb4954160/7f308/ioBroker_and_INSTAR_Alarmserver_05.avif 920w", "/en/static/c105960cca79fdb38be7d48bb4954160/7118b/ioBroker_and_INSTAR_Alarmserver_05.avif 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c105960cca79fdb38be7d48bb4954160/a0b58/ioBroker_and_INSTAR_Alarmserver_05.webp 230w", "/en/static/c105960cca79fdb38be7d48bb4954160/bc10c/ioBroker_and_INSTAR_Alarmserver_05.webp 460w", "/en/static/c105960cca79fdb38be7d48bb4954160/966d8/ioBroker_and_INSTAR_Alarmserver_05.webp 920w", "/en/static/c105960cca79fdb38be7d48bb4954160/85e27/ioBroker_and_INSTAR_Alarmserver_05.webp 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c105960cca79fdb38be7d48bb4954160/81c8e/ioBroker_and_INSTAR_Alarmserver_05.png 230w", "/en/static/c105960cca79fdb38be7d48bb4954160/08a84/ioBroker_and_INSTAR_Alarmserver_05.png 460w", "/en/static/c105960cca79fdb38be7d48bb4954160/c0255/ioBroker_and_INSTAR_Alarmserver_05.png 920w", "/en/static/c105960cca79fdb38be7d48bb4954160/2e694/ioBroker_and_INSTAR_Alarmserver_05.png 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c105960cca79fdb38be7d48bb4954160/c0255/ioBroker_and_INSTAR_Alarmserver_05.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The URL must be broken down in the Alarm Server mask of `}<strong parentName="p">{`camera 1`}</strong>{` as follows:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alarmserver Adresse`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`192.168.2.111`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Serverport`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8087`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Serverpfad`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/set/mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Parameter 1`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`value`}</code>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code></li>
    </ul>
    <p>{`Now you can arm the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{` and / or the internal `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`PIR Sensor`}</a>{` to trigger the alarm server when there is movement in front of the camera.`}</p>
    <p>{`In the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`PTZ Settings`}</a>{` of camera 2, the idle position (home position) must be set to position `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`. This ensures that the camera always returns to its home position after it was triggered.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      